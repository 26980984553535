import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
  }),
}

export const authMethods = mapActions('auth', ['logIn', 'logOut'])

export const layoutMethods = mapActions('layout', [
  'changeLayoutType',
  'changeLayoutWidth',
  'changeLeftSidebarType',
  'changeTopbar',
  'changeLoaderValue',
])

export const authFackMethods = mapActions('authfack', [
  'login',
  'registeruser',
  'logout',
])

export const notificationMethods = mapActions('notification', [
  'success',
  'error',
  'clear',
])

export const employeeMethods = mapActions('employees', [
  'getEmployees',
  'createEmployee',
  'deleteEmployee',
])

export const userMethods = mapActions('users', ['getUserByEmail'])

export const departmentMethods = mapActions('departments', [
  'getDepartments',
  'createDepartment',
  'deleteDepartment',
])

export const jobTitleMethods = mapActions('jobTitles', [
  'getJobTitles',
  'createJobTitle',
  'deleteJobTitle',
])

export const workPlanMethods = mapActions('workPlans', [
  'getWorkPlans',
  'createWorkPlan',
  'deleteWorkPlan',
])

export const orgMethods = mapActions('organizations', ['getTree'])

export const attendanceTypeMethods = mapActions('attendanceTypes', [
  'getAttendanceTypes',
  'createAttendanceType',
  'deleteAttendanceType',
])

export const leaveTypeMethods = mapActions('leaveTypes', [
  'getLeaveTypes',
  'createLeaveType',
  'deleteLeaveType',
])

export const payrollExtraMethods = mapActions('payrollExtras', [
  'getPayrollExtras',
  'createPayrollExtra',
  'deletePayrollExtra',
])
